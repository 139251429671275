.modal>.header {
    border-bottom: 1px dashed #fff;
    font-size: 18px;
    text-align: center;
    padding: 5px;
}

.modal h2 {
    font-family: 'Tilt Warp', cursive;
}

.modal>.content {
    padding: 10px 15px;
    font-size: 1.1rem;
}

.modal>.actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 200px));
    gap: 15px;
    justify-content: center;
}

.modal>.actions button {
    padding: 17px 20px;
    border-radius: 10px;
    border: 0;
    width: auto;
    background-color: #1d9bf0;
    font-size: 15px;
    font-weight: bold;
    transition: all .3s ease;
    box-shadow: #0a5f98 0px 10px 0px 0px;
    color: #fff;
    font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.modal>.actions button:hover {
    border-color: rgba(0, 0, 0, 0.15);
    transform: translateY(-1px);
}

.modal>.actions button:active {
    /*50, 168, 80*/
    box-shadow: #0a5f98 0px 0px 0px 0px;
    transform: translateY(5px);
    transition: 200ms;
}

.modal>.close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #fff;
}

.popup-content {
    margin: 0;
    padding: 20px;
    width: 80%;
    max-width: 500px;
    border: none;
    border-radius: 5px;
    text-align: center;
    background-color: #1d9bf0;
    color: #fff;
    font-family: 'Noto Sans', sans-serif;
    /* background-image: linear-gradient(to top, #f77062 0%, #fe5196 100%); */
    background-image: linear-gradient(to right, #ed6ea0 0%, #ec8c69 100%);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    background: linear-gradient(to bottom, #323232 0%, #3F3F3F 40%, #1C1C1C 150%), linear-gradient(to top, rgba(255, 255, 255, 0.40) 0%, rgba(0, 0, 0, 0.25) 200%);
    background-blend-mode: multiply;


}

.popup-overlay {
    -webkit-animation: fadein 0.5s;
    /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 0.5s;
    /* Firefox < 16 */
    -ms-animation: fadein 0.5s;
    /* Internet Explorer */
    -o-animation: fadein 0.5s;
    /* Opera < 12.1 */
    animation: fadein 0.5s;
}

.footer {
    margin-top: 20px;
}

.footer a {
    color: #fff;
    /* text-decoration: none; */
    font-size: 0.9rem;
    font-weight: bold;
    margin: 0 5px;
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}