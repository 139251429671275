@import url('https://fonts.googleapis.com/css2?family=DynaPuff:wght@400;500;600;700&family=Fraunces:ital,wght@0,600;0,700;0,800;0,900;1,400;1,500&family=Jura:wght@400;500;600;700&family=Marhey:wght@400;500;600;700&family=Noto+Sans:ital,wght@0,300;0,400;1,300&family=Red+Rose:wght@400;500;600;700&family=Roboto+Condensed&family=Tilt+Warp&display=swap');

body,
html {
  min-height: 100%;
  height: 100%;
  background-color: #fafafa;
}

body {
  margin: 0;
  font-family: 'Noto Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tweet {
  background-color: #1d9bf0;
  color: #fff;
  border-radius: 10px;
  border: none;
  padding: 5px 10px;
  font-weight: bold;
}

canvas:last-of-type{
  z-index: 99999999!important;
}